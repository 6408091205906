const values = {
	user: null,
	sessionList: [],
	isFetchingSessions: false,
	gameList: [],
	isFetchingGames: false,
	gameNames: [],
	isFetchingNames: false,
	gameIds: [],
	isFetchingId: [],
	bbCode: "",
	isFetchingBBCode: false,
	syncResults: "",
	syncTotalsResults: "",
	isSyncing: false,
	firstPlays: [],
	isFetchingFirstPlays: false,
	fiftyPlays: [],
	isFetchingFiftyPlays: false,
	monthList: [],
	isFetchingMonths: false,
	yearList: [],
	isFetchingYears: false,
}

export default values